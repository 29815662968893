import { Button, FormGroup, Icon, InputGroup, Intent, TextArea } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React from "react";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import data from "../../../package.json";
import { backendUrl } from "../../settings";
import { IUser } from "../../types/types";
import { UserContext } from "../WithUserContext";
import { Notifications } from "./notifications";

export const ContactForm = () => {
  const userData = useContext<IUser | undefined>(UserContext);
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>(userData?.email || "");
  const [title, setTitle] = useState<string>("");

  const [text, setText] = useState<string>("");
  const [name, setName] = useState<string>("");

  const submit = useCallback(async () => {
    try {
      const formData = new FormData();
      const text = document.getElementsByTagName("textarea")[0].value;

      formData.append("text", text);
      formData.append("email", email);
      formData.append("title", title);

      formData.append("name", name);
      formData.append("url", window.location.href);
      formData.append("version", data.version);

      const response = await fetch(`${backendUrl}/send-email`, {
        method: "POST",
        headers: {},
        body: formData,
      });
      if (response.ok) {
        Notifications &&
          Notifications.show({
            message: t("messages.message_has_been_sent") as string,
            intent: Intent.SUCCESS,
          });
      } else {
        Notifications &&
          Notifications.show({
            message: t("messages.there_was_an_error_processing_your_request") as string,
            intent: Intent.DANGER,
          });
        throw new Error(t("messages.there_was_an_error_processing_your_request"));
      }
    } catch (err: unknown) {
      Notifications &&
        Notifications.show({
          message: t("messages.there_was_an_error_processing_your_request") as string,
          intent: Intent.DANGER,
        });
      if (err instanceof Error) throw err;
      else throw new Error(err as string);
    } finally {
      setName("");
      setEmail("");
      setTitle("");
      setText("");
    }
  }, [userData, name, title, email]);

  return (
    <div className="mx-auto w-full max-w-md mt-12 text-left">
      <FormGroup helperText={t("messages.contact_form")}>
        <InputGroup
          className="mb-3"
          placeholder={t("labels.name")}
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
          leftElement={<Icon icon={IconNames.USER} />}
          type="text"
        />
        <InputGroup
          className="mb-3"
          required
          placeholder={t("labels.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          readOnly={!_.isEmpty(userData)}
          leftElement={<Icon icon={IconNames.ENVELOPE} />}
          type="text"
        />
        <InputGroup
          className="mb-3"
          required
          placeholder={t("labels.title")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          leftElement={<Icon icon={IconNames.EDIT} />}
          type="text"
        />
        <TextArea className="mb-2 w-full" value={text} onChange={(e) => setText(e.target.value)} />
      </FormGroup>
      <Button
        intent={Intent.SUCCESS}
        className="bp5-dark mt-6 w-full mb-12"
        onClick={submit}
        text={t("labels.send")}
        icon={IconNames.SEND_MESSAGE}
        disabled={_.isEmpty(name) || _.isEmpty(email) || _.isEmpty(text)}
      />
    </div>
  );
};
