import { useQuery } from "@apollo/client";
import { Icon, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React from "react";
import { ReactElement, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CATEGORIES_LIST, CATEGORY, CATEGORY_FOR_YEAR } from "../../graphql/queries/categories";
import useSelectedDate from "../../hooks/useSelectedDate";
import { ICategory } from "../../types/types";
import GraphQlError from "../common/GraphQlError";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import Responsive from "../common/Responsive";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import { EntriesTable } from "../entries/Entries";
import CategoryChart from "./CategoryChart";
import { BudgetContext } from "../WithBudgetContext";

export default function Category(): ReactElement {
  const { categoryId } = useParams<{ categoryId: string }>();

  const {
    selectedDate,
    setCurrentMonthAsSelectedDate,
    setPrevMonthAsSelectedDate,
    setNextMonthAsSelectedDate,
  } = useSelectedDate();

  const {
    data,
    loading,
    error,
    refetch: refetchCategory,
  } = useQuery(CATEGORY, {
    variables: {
      categoryId: categoryId && parseInt(categoryId),
      periodStart: selectedDate.startOf("month"),
      periodEnd: selectedDate.endOf("month"),
    },
  });

  const {
    data: dataForChart,
    loading: loadingDataForChart,
    error: categoryForYearError,
    refetch: refetchCategoryForYear,
  } = useQuery(CATEGORY_FOR_YEAR, {
    variables: {
      categoryId: categoryId && parseInt(categoryId),
      periodStart: selectedDate.startOf("year"),
      periodEnd: selectedDate.endOf("year"),
    },
  });

  const refetchAll = useCallback(() => {
    return Promise.all([refetchCategory(), refetchCategoryForYear()]);
  }, []);

  const { categories_by_pk: category, splitted_entries } = data || {};

  const entries = useMemo(() => {
    const tmp = _.chain(splitted_entries)
      .map("entry")
      .map((entry) => {
        if (_.isEmpty(entry.splitted_entries)) {
          return entry;
        } else {
          return _.map(entry.splitted_entries, (splittedEntry) => {
            return {
              ...entry,
              ...splittedEntry,
            };
          });
        }
      })
      .flatten()
      .filter((entry) => entry.category)
      .filter((entry) => entry.category.id === parseInt(categoryId || "0"))
      .uniqBy((entry) => entry.id)
      .sortBy("date")
      .reverse()
      .value();

    return tmp;
  }, [splitted_entries]);

  const totalForYear = useMemo(() => {
    return dataForChart
      ? _.reduce(
          dataForChart.splitted_entries,
          (acc, entry) => {
            return acc + entry.value;
          },
          0
        )
      : 0;
  }, [dataForChart]);

  console.log({ dataForChart });

  return (
    <GraphQlError error={error || categoryForYearError}>
      <div className="table-with-chart">
        <div className="category__entries-table">
          <EntriesTable
            currentPage="category"
            loading={loading}
            entries={entries}
            columns={["value", "account", "payee", "description", "actions"]}
            entrySwitcher={<CategorySelect currentCategory={category} />}
            selectedDate={selectedDate}
            setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
            setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
            setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
            refetch={refetchAll}
            initialForm={{ category }}
          />
        </div>
        <Responsive displayIn={["Laptop"]}>
          <div className="category__entries-chart">
            <WithLoadingSpinner isLoading={loadingDataForChart}>
              <h2 className="bp5-heading text-right mx-6">
                <span className="inline">
                  {category?.name} <MoneyValue value={totalForYear} />
                </span>
              </h2>
              <CategoryChart data={dataForChart} />
            </WithLoadingSpinner>
          </div>
        </Responsive>
      </div>
    </GraphQlError>
  );
}

const CategorySelect = ({ currentCategory }: { currentCategory: ICategory }) => {
  const currentBudget = useContext(BudgetContext);
  const {
    data,
    loading: loadingCategories,
    error,
  } = useQuery(CATEGORIES_LIST, { variables: { budgetId: currentBudget?.id } });

  const { categories } = data || {};

  const getIcon = useCallback(
    (categoryName = "") =>
      categoryName.toLowerCase().indexOf("transfer") !== -1
        ? IconNames.CHANGES
        : IconNames.ARRAY_NUMERIC,
    []
  );
  return (
    <WithLoadingSpinner isLoading={loadingCategories}>
      <GraphQlError error={error}>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              {_.map(categories || [], (acc) => (
                <MenuItem
                  icon={getIcon(acc.name)}
                  key={acc.id}
                  tagName="div"
                  multiline
                  className="break-all"
                  text={
                    <InternalLink className="break-all" to={`/categories/${acc.id}`}>
                      {acc.name}
                    </InternalLink>
                  }
                />
              ))}
            </Menu>
          }
        >
          <div className="flex flex-row items-center cursor-pointer">
            <Icon icon={getIcon(currentCategory?.name)} />{" "}
            <div className="mx-2 line-clamp-2">{currentCategory?.name} </div>
            <Icon icon={IconNames.CHEVRON_DOWN} />
          </div>
        </Popover>
      </GraphQlError>
    </WithLoadingSpinner>
  );
};
