import { useMutation } from "@apollo/client";
import { Icon, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { KeyboardEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CREATE_INCOME,
  INLINE_UPDATE_INCOME,
  SOFT_DELETE_INCOME,
} from "../../graphql/mutations/incomes";
import { INCOMES } from "../../graphql/queries/incomes";
import { IIncome } from "../../types/types";
import { graphQlError, parseDate } from "../../utils/utils";
import Actions from "../common/Actions";
import MoneyValue from "../common/MoneyValue";
import { Notifications } from "../common/notifications";
import { ValueInput } from "../forms/FormBuilder";
import { BudgetContext } from "../WithBudgetContext";
import { UserContext } from "../WithUserContext";

type TEditingKey = keyof IIncome;
type TEditing = { key: TEditingKey; value: string | number } | null;

const Income = ({
  income,
  index,
  selectedDate,
  openDeleteDialog,
  onEdit,
}: {
  income: IIncome;
  index: number;
  selectedDate: DateTime;
  openDeleteDialog: (arg0: IIncome) => void;
  onEdit: (income: IIncome) => void;
}) => {
  const userData = useContext(UserContext);
  const currentBudget = useContext(BudgetContext);

  const { t } = useTranslation();

  const [editing, setEditing] = useState<TEditing>(null);
  const [createIncome] = useMutation(CREATE_INCOME, {
    refetchQueries: [{ query: INCOMES, variables: { budgetId: currentBudget?.id } }],
  });
  const [deleteIncome] = useMutation(SOFT_DELETE_INCOME, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INCOMES, variables: { budgetId: currentBudget?.id } }],
  });
  const [updateIncomeInline] = useMutation(INLINE_UPDATE_INCOME, {
    refetchQueries: [{ query: INCOMES, variables: { budgetId: currentBudget?.id } }],
  });

  const update = () => {
    const { key, value } = editing || {};

    if (!key || !value) {
      setEditing(null);
      throw new Error("Key or value is missing from the editing object");
    }

    if (income[key] && income[key] === value) {
      setEditing(null);
      return;
    }

    const entryMonth = parseDate(income.income_date).toFormat("M");
    const selectedMonth = selectedDate.toFormat("M");
    (selectedMonth === entryMonth
      ? updateIncomeInline({
          variables: {
            id: income.id,
            changes: {
              [key]: value,
              income_date: income.id ? income.income_date : selectedDate,
            },
          },
        })
      : Promise.all([
          createIncome({
            variables: {
              object: {
                ..._.omit(income, ["id", "__typename", "created_at", "update_at"]),
                budget_id: currentBudget?.id,
                user_id: userData?.user_id,
                income_date: selectedDate,
                [key]: value,
              },
            },
          }),
          deleteIncome({ variables: { id: income.id, deleted_at: selectedDate } }),
        ])
    )
      .then(() => {
        Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      .catch(graphQlError)
      .finally(() => setEditing(null));
  };

  const cancelEditing = (event: React.MouseEvent): void => {
    if (event instanceof MouseEvent) event.stopPropagation();
    setEditing(null);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") update();
    if (e.key === "Escape") {
      setEditing(null);
    }
  };

  return (
    <tr key={income.id}>
      <td className="budget-table-no">{index}</td>
      <td>
        {editing?.key === "name" ? (
          <div className="flex flex-row relative">
            <InputGroup
              type="text"
              className="w-full py-1 pl-1 pr-10"
              value={editing?.value as string}
              onChange={(e) => setEditing({ key: "name", value: e.target.value })}
              onKeyDown={onKeyDown}
            />
            <div className="flex flex-row absolute right-0 top-2">
              <Icon icon={IconNames.TICK} onClick={update} />
              <Icon icon={IconNames.CROSS} onClick={cancelEditing} />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setEditing({ key: "name", value: income.name });
            }}
          >
            {income.name}
          </div>
        )}
      </td>
      <td className="budget-table-amount">
        {editing?.key === "value" ? (
          <div className="flex flex-row relative">
            <ValueInput
              entityKey="value"
              onChange={(key, value) => setEditing({ key, value } as TEditing)}
              onKeyDown={onKeyDown}
              value={editing.value}
            />
            <div className="flex flex-row absolute right-0 top-2">
              <Icon icon={IconNames.TICK} onClick={update} />
              <Icon icon={IconNames.CROSS} onClick={cancelEditing} />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setEditing({ key: "value", value: income.value });
            }}
          >
            <MoneyValue value={income.value} />
          </div>
        )}
      </td>
      <td className="budget-table-amount" onBlur={update}>
        {editing?.key === "actual_value" ? (
          <div className="flex flex-row relative">
            <ValueInput
              entityKey="actual_value"
              onChange={(key, value) => setEditing({ key, value } as TEditing)}
              onKeyDown={onKeyDown}
              value={editing.value}
            />
            <div className="flex flex-row absolute right-0 top-2">
              <Icon icon={IconNames.TICK} onClick={update} />
              <Icon icon={IconNames.CROSS} onClick={cancelEditing} />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setEditing({ key: "actual_value", value: income.actual_value });
            }}
          >
            <MoneyValue value={income.actual_value} />
          </div>
        )}
      </td>
      <td className="budget-table-actions">
        <Actions entry={income} onDelete={openDeleteDialog} onEdit={onEdit} />
      </td>
    </tr>
  );
};

export default Income;
