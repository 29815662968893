import { Position, Tooltip } from "@blueprintjs/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import React from "react";

const EntriesTHead = ({ columns }: { columns: string[] }) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        {_.map(columns, (column) => {
          return (
            <th
              key={column}
              className={`budget-table-${column} border-collapse border border-gray-200 dark:border-gray-700 p-2`}
            >
              {["balance"].indexOf(column) !== -1 ? (
                <Tooltip
                  position={Position.BOTTOM}
                  content={<div>{t(`table.columns.${column}`)}</div>}
                >
                  <div className="w-full">{t(`table.columns.${column}`)}</div>
                </Tooltip>
              ) : (
                <div className="w-full">{t(`table.columns.${column}`)}</div>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default EntriesTHead;
