import { useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, HTMLTable } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  CREATE_ESTIMATE_ENTRY,
  DELETE_ESTIMATE_ENTRY,
  UPDATE_ESTIMATE_ENTRY,
} from "../../graphql/mutations/estimate_entry";
import { ESTIMATE } from "../../graphql/queries/estimates";
import { IEstimateEntry } from "../../types/types";
import { UserContext } from "../WithUserContext";
import Actions from "../common/Actions";
import { ConfirmDialog } from "../common/ConfirmDialog";
import MoneyValue from "../common/MoneyValue";
import TableHeader from "../common/TableHeader";
import EstimateEntryForm from "../forms/EstimateEntryForm";
import EstimateSelect from "./EstimateSelect";

const Estimate = () => {
  const userData = useContext(UserContext);
  const { t } = useTranslation();
  const { estimateId } = useParams<{ estimateId: string }>();
  const { data, loading, error } = useQuery(ESTIMATE, { variables: { estimateId } });

  const [estimateEntry, setEstimateEntry] = useState<IEstimateEntry | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [entryToBeDeleted, setEntryToBeDeleted] = useState<IEstimateEntry | null>(null);
  const createNewEstimate = useCallback(
    () =>
      setEstimateEntry({ name: "", planned: 0, real: 0, estimate_id: parseInt(estimateId || "0") }),
    [estimateId]
  );
  const closeDialog = useCallback(() => setEstimateEntry(null), []);

  const onChange = (key: string, value: any): void => {
    setEstimateEntry(_.extend({}, estimateEntry, { [key]: value }));
  };

  const [createEstimate] = useMutation(CREATE_ESTIMATE_ENTRY, {
    refetchQueries: [{ query: ESTIMATE, variables: { estimateId } }],
  });

  const [updateEstimate] = useMutation(UPDATE_ESTIMATE_ENTRY, {
    refetchQueries: [{ query: ESTIMATE, variables: { estimateId } }],
  });

  const [deleteEstimate] = useMutation(DELETE_ESTIMATE_ENTRY, {
    refetchQueries: [{ query: ESTIMATE, variables: { estimateId } }],
  });

  const onDelete = (estimateId: number) => deleteEstimate({ variables: { id: estimateId } });

  const onSave = (estimate: IEstimateEntry) => {
    return (
      !estimate.id
        ? createEstimate({
            variables: {
              object: {
                ..._.omit(estimate, ["__typename", "category"]),
                category_id: estimate.category?.id,
                user_id: userData?.user_id,
              },
            },
          })
        : updateEstimate({
            variables: {
              ..._.omit(estimate, ["__typename"]),
            },
          })
    ).then(() => closeDialog());
  };

  const onEdit = (estimate: IEstimateEntry) => {
    setEstimateEntry(estimate);
  };

  const openDeleteDialog = useCallback((entry: IEstimateEntry) => {
    setDeleteDialogOpen(true);
    setEntryToBeDeleted(entry);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
    setEntryToBeDeleted(null);
  }, []);

  const { estimate_entries } = data?.estimates_by_pk || {};

  const totalPlanned = useMemo(() => {
    return _.reduce(
      estimate_entries,
      (acc, entry) => {
        return (acc += entry.planned);
      },
      0
    );
  }, [estimate_entries]);

  const totalReal = useMemo(() => {
    return _.reduce(
      estimate_entries,
      (acc, entry) => {
        return (acc += entry.real);
      },
      0
    );
  }, [estimate_entries]);

  return (
    <div>
      <Dialog
        usePortal
        isOpen={!_.isEmpty(estimateEntry)}
        onClose={closeDialog}
        title={
          estimateEntry?.id
            ? t("actions.edit_estimate", { name: estimateEntry?.name })
            : t("actions.add_new_estimate_entry")
        }
      >
        <EstimateEntryForm
          estimate={estimateEntry as IEstimateEntry}
          onChange={onChange}
          onApply={onSave}
          onCancel={closeDialog}
        />
      </Dialog>
      <ConfirmDialog
        icon={IconNames.WARNING_SIGN}
        title={t("warnings.delete_estimate")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t("warnings.remove_estimate_entry_confirmation", {
                name: entryToBeDeleted?.name,
              }),
            }}
          />
        }
        onCancel={closeDeleteDialog}
        isOpen={deleteDialogOpen && !_.isEmpty(entryToBeDeleted)}
        onApply={() => {
          onDelete(entryToBeDeleted?.id as number);
          closeDeleteDialog();
        }}
      />

      {data && (
        <div>
          <TableHeader
            rightElement={
              <Button
                icon={IconNames.ADD}
                text={t("actions.add_estimate_entry")}
                onClick={createNewEstimate}
              />
            }
            leftElement={
              <div>
                <EstimateSelect currentEstimate={data.estimates_by_pk} />
              </div>
            }
          />
          <HTMLTable
            bordered
            compact
            striped
            className="entries-table w-full max-w-full table-fixed dark:text-gray-100"
          >
            <thead>
              <tr>
                <th className="budget-table-name">{t("labels.name")}</th>
                <th className="budget-table-name">{t("labels.planned")}</th>
                <th className="budget-table-name">{t("labels.real")}</th>
                <th className="budget-table-name">{t("labels.category")}</th>
                <th className="budget-table-actions" />
              </tr>
            </thead>
            <tbody>
              {_.map(estimate_entries, (entry) => (
                <EstimateRow
                  key={entry.id}
                  entry={entry}
                  onEdit={onEdit}
                  openDeleteDialog={openDeleteDialog}
                />
              ))}
              <tr>
                <td></td>
                <td>
                  {t("labels.planned")}: <MoneyValue value={totalPlanned} />
                </td>
                <td>
                  {t("labels.real")}: <MoneyValue value={totalReal} />
                </td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </HTMLTable>
        </div>
      )}
    </div>
  );
};

interface IEstimateRow {
  entry: IEstimateEntry;
  onEdit: (entry: IEstimateEntry) => void;
  openDeleteDialog: (entry: IEstimateEntry) => void;
}

export const EstimateRow = ({ entry, onEdit, openDeleteDialog }: IEstimateRow) => {
  const rowClass = useMemo(
    () =>
      entry.real > entry.planned ? "higher" : entry.real === entry.planned ? "equal" : "lower",
    [entry]
  );

  return (
    <tr className={`budget-entry budget-entry--${rowClass}`}>
      <td>{entry.name}</td>
      <td>
        <MoneyValue value={entry.planned} />
      </td>
      <td>
        <MoneyValue value={entry.real} />
      </td>
      <td>{entry.category?.name}</td>

      <td className="budget-table-actions">
        <Actions
          entry={entry}
          onDelete={() => openDeleteDialog(entry)}
          onEdit={() => onEdit(entry)}
        />
      </td>
    </tr>
  );
};

export default Estimate;
