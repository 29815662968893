import { useQuery } from "@apollo/client";
import { Alert, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BUDGET_SECTIONS } from "../../graphql/queries/budgetSections";
import { CATEGORIES } from "../../graphql/queries/categories";
import { INCOMES } from "../../graphql/queries/incomes";
import useSelectedDate from "../../hooks/useSelectedDate";
import { IBudget, ICategory, IIncome } from "../../types/types";
import {
  getBindedCategoriesIds,
  getThisMonthBudgetEntries,
  getThisMonthIncomes,
  getTotalIncome,
  getTotalPlannedSpendingsInCurrentMonth,
  getTotalRealIncome,
  getUnbindedCategories,
} from "../../utils/budgetUtils";
import { exportDashboardToCsv } from "../../utils/utils";
import Responsive from "../common/Responsive";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import Incomes from "../incomes/Incomes";
import BudgetCharts from "./BudgetCharts";
import BudgetHeader from "./BudgetHeader";
import BudgetSections from "./BudgetSections";
import BudgetSummary from "./BudgetSummary";
import { ExportOptions } from "../common/ExportDialog";

interface BudgetProps {
  budget: IBudget;
}

export default function Budget({ budget }: BudgetProps): ReactElement {
  const { t } = useTranslation();
  const [unbindedCategoriesAlertOpen, setUnbindedCategoriesAlert] = useState(false);

  const {
    selectedDate,
    setCurrentMonthAsSelectedDate,
    setPrevMonthAsSelectedDate,
    setNextMonthAsSelectedDate,
  } = useSelectedDate();

  const { data: categories } = useQuery(CATEGORIES, { variables: { budgetId: budget.id } });
  const { data: incomes, loading: loadingIncomes } = useQuery(INCOMES, {
    variables: { budgetId: budget.id },
  });
  const { data: budgetSections, loading: loadingBudgetSections } = useQuery(BUDGET_SECTIONS, {
    variables: {
      budgetId: budget.id,
      periodStart: selectedDate.startOf("month"),
      periodEnd: selectedDate.endOf("month"),
    },
  });

  const budget_entries = useMemo(() => {
    return _.flatten(_.map(budgetSections?.budget_sections, "budget_entries"));
  }, [budgetSections]);

  const endCurrentMonth = useMemo(() => selectedDate.endOf("month"), [selectedDate]);

  const thisMonthIncomes: IIncome[] = useMemo(
    () => getThisMonthIncomes(incomes?.incomes, endCurrentMonth),
    [incomes, endCurrentMonth]
  );

  //TODO refactor this to one function
  const totalIncome = useMemo(() => getTotalIncome(thisMonthIncomes), [thisMonthIncomes]);
  const totalRealIncome = useMemo(() => getTotalRealIncome(thisMonthIncomes), [thisMonthIncomes]);

  const thisMonthBudgetEntries = useMemo(
    () => getThisMonthBudgetEntries(budget_entries, endCurrentMonth),
    [budget_entries, endCurrentMonth]
  );

  const totalPlannedSpendingsInCurrentMonth = useMemo(
    () => getTotalPlannedSpendingsInCurrentMonth(thisMonthBudgetEntries),
    [thisMonthBudgetEntries]
  );

  const bindedCategoriesIds = useMemo(
    () => getBindedCategoriesIds(budgetSections?.budget_sections),
    [budgetSections]
  );

  const unbindedCategories = useMemo(
    () => getUnbindedCategories(categories?.categories, bindedCategoriesIds),
    [categories, bindedCategoriesIds]
  );

  const showUnbindedCategoriesAlert = useCallback(() => {
    setUnbindedCategoriesAlert(true);
  }, []);

  const closeUnbindedCategoriesAlert = useCallback(() => {
    setUnbindedCategoriesAlert(false);
  }, []);

  const exportDashboard = useCallback(
    (exportOptions: ExportOptions) => {
      exportDashboardToCsv(exportOptions, thisMonthIncomes, budgetSections?.budget_sections);
    },
    [thisMonthIncomes, budgetSections]
  );

  return (
    <div>
      <div className="budget-header">
        <BudgetHeader
          setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
          setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
          setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
          selectedDate={selectedDate}
          onExport={exportDashboard}
        />
      </div>
      <div className="budget-content-scrollable">
        <WithLoadingSpinner isLoading={loadingIncomes}>
          <Incomes
            selectedDate={selectedDate}
            incomes={thisMonthIncomes}
            totalIncome={totalIncome}
            totalRealIncome={totalRealIncome}
          />
        </WithLoadingSpinner>
        <div className="mb-4 flex flex-row items-center">
          <BudgetSummary
            totalIncome={totalIncome}
            selectedDate={selectedDate}
            totalPlannedSpendingsInCurrentMonth={totalPlannedSpendingsInCurrentMonth}
          />
          {!_.isEmpty(unbindedCategories) && (
            <React.Fragment>
              <Icon
                onClick={showUnbindedCategoriesAlert}
                className="mx-3 cursor-pointer"
                icon={IconNames.WARNING_SIGN}
                intent={Intent.WARNING}
              />

              <Alert
                isOpen={unbindedCategoriesAlertOpen}
                icon={IconNames.WARNING_SIGN}
                intent={Intent.WARNING}
                confirmButtonText={t("labels.ok")}
                cancelButtonText={t("labels.cancel")}
                onConfirm={closeUnbindedCategoriesAlert}
                onCancel={closeUnbindedCategoriesAlert}
                canEscapeKeyCancel
              >
                <div>
                  <p>{t("messages.unbinded_categories", { count: unbindedCategories.length })}</p>
                  <div className="mt-2">
                    {unbindedCategories.map((category: ICategory) => (
                      <div key={category.id}>{category.name}</div>
                    ))}
                  </div>
                </div>
              </Alert>
            </React.Fragment>
          )}
        </div>
        <WithLoadingSpinner isLoading={loadingBudgetSections}>
          <>
            <BudgetSections
              selectedDate={selectedDate}
              budgetSections={budgetSections?.budget_sections}
            />
            <Responsive displayIn={["Laptop"]}>
              <BudgetCharts
                selectedDate={selectedDate}
                budgetSections={budgetSections?.budget_sections}
              />
            </Responsive>
          </>
        </WithLoadingSpinner>
      </div>
    </div>
  );
}
