import gql from "graphql-tag";
import { BUDGET_ENTRY_FRAGMENT } from "./budgetEntries";

export const BUDGET_SECTIONS = gql`
  ${BUDGET_ENTRY_FRAGMENT}
  query BudgetSections($budgetId: Int!, $periodStart: timestamptz!, $periodEnd: timestamptz!) {
    budget_sections(where: { budget_id: { _eq: $budgetId } }, order_by: { order_number: asc }) {
      id
      order_number
      section_name
      budget_entries: budget_entries(
        where: { _or: [{ deleted_at: { _is_null: true } }, { deleted_at: { _gt: $periodEnd } }] }
        order_by: { value: desc }
      ) {
        ...BudgetEntryFragment
      }
    }
  }
`;
