import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IEntry } from "../../types/types";
import { prepareEntriesForAccountChart } from "../entries/entriesUtils";

type Props = {
  entries: IEntry[];
};

const AccountChart = ({ entries }: Props) => {
  const data = prepareEntriesForAccountChart(entries);
  const { t } = useTranslation();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
        barGap={-15}
        maxBarSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend
          verticalAlign="top"
          layout="horizontal"
          formatter={(value) => {
            return t(`labels.${value}`);
          }}
        />
        <Bar dataKey="expense" fill="#0C5174" />
        <Bar dataKey="income" fill="#238C2C" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AccountChart;
