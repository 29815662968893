import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Browser } from "@capacitor/browser";
import { useTranslation } from "react-i18next";
import React from "react";

const MobileLoginBtn = () => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const login = async () => {
    try {
      await loginWithRedirect({
        async openUrl(url) {
          // Redirect using Capacitor's Browser plugin
          await Browser.open({
            url,
            windowName: "_self",
          });
        },
      });
    } catch (e) {
      console.error({ e });
    }
  };
  return (
    <Button
      minimal
      className="text-2xl md:text-base"
      onClick={() => login()}
      text={t("labels.log_in")}
      icon={IconNames.USER}
    />
  );
};

export default MobileLoginBtn;
