import _ from "lodash";
import { DateTime } from "luxon";
import i18n from "../../i18n";
import { IEntry, ISingleEntry, ISplittedEntry } from "../../types/types";
import { ENTRY_TYPES } from "../../utils/constants";
import { parseDate } from "../../utils/utils";

export const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const prepareEntryForInsert = (entry: IEntry, accountId?: number, categoryId?: number) => {
  return {
    ..._.omit(entry, ["id", "__typename", "category", "account", "payee", "payees"]),
    category_id: categoryId,
    account_id: accountId,
    payee_id: entry.payee?.id,
    value: entry.value,
    type: entry.type,
    date: entry.date,
    related_entry: entry.id,
    splitted_entries: {
      data: entry.splitted_entries
        ? //@ts-ignore
          prepareSplittedEntries(entry.splitted_entries, entry.user_id)
        : [],
      on_conflict: {
        constraint: "splitted_entries_pkey",
        update_columns: ["value", "category_id"],
      },
    },
  };
};

export const filterEntriesForCurrentMonth = (
  entries: Partial<IEntry[] | ISplittedEntry[]>,
  selectedDate: DateTime,
  searchQuery = ""
): IEntry[] =>
  _.filter(entries, (entry: IEntry | ISplittedEntry) => {
    return (
      selectedDate.get("month") === parseDate(entry.date).get("month") &&
      selectedDate.get("year") === parseDate(entry.date).get("year") &&
      (!_.isEmpty(searchQuery)
        ? entry.description?.toLowerCase().indexOf(searchQuery) !== -1 ||
          `${entry.value}`.toString().indexOf(searchQuery) !== -1 ||
          entry.category?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          entry.account?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        : true)
    );
  }) as IEntry[];

export const prepareSplittedEntries = (
  splittedEntries: ISingleEntry[],
  user_id: number,
  entry_id: number
) => {
  return _.map(splittedEntries, (entry) => {
    return {
      ..._.omit(entry, "__typename", "category", "value"),
      entry_id,
      user_id,
      value: parseFloat(entry.value.toString()),
      category_id: entry.category?.id,
    };
  });
};

export const prepareEntriesForAccountChart = (
  entries: Partial<IEntry>[]
): {
  name: string;
  [key: string]: number | string;
}[] =>
  _.map(
    _.reduce(
      entries,
      (acc: { [key: number | string]: any }, entry) => {
        const { type, splitted_entries } = entry;

        const entriesWithSplittedEntries = [
          _.isEmpty(splitted_entries) ? entry : null,
          ...(splitted_entries as ISingleEntry[]),
        ];

        _.forEach(
          _.groupBy(_.compact(entriesWithSplittedEntries), (splitted_entry) => {
            return splitted_entry.category?.id;
          }),
          (group, key) => {
            const values = _.map(group, (g) => {
              return {
                categoryId: g.category?.id,
                name: g.category?.name,
                value: g.value,
                type: type,
              };
            });
            if (!acc[key]) {
              acc[key] = values;
            } else {
              acc[key] = acc[key].concat(values);
            }
          }
        );
        return acc;
      },
      {}
    ),
    (categoryValues, categoryId) => {
      const value = _.reduce(
        categoryValues,
        (acc: { [key: string]: number }, category) => {
          if (acc[category.type]) {
            acc[category.type] += category.value;
          } else {
            acc[category.type] = category.value;
          }
          return acc;
        },
        {}
      );
      return {
        categoryId,
        ...value,
        //@ts-ignore
        name: _.first(categoryValues).name,
      };
    }
  );

export const prepareEntriesForCategoryChart = (data: {
  entries: IEntry[];
  splitted_entries: ISplittedEntry[];
}) => {
  const { entries, splitted_entries } = data;

  const grouppedByMonths = _.groupBy(
    [
      ...entries,
      ..._.map(splitted_entries, (splitted_entry) => ({
        ..._.omit(splitted_entry, "entry"),
        ...splitted_entry.entry,
      })),
    ],
    (entry) => {
      return parseDate(entry.date).get("month");
    }
  );

  const templateArray = Array(12).fill({ name: "", expense: 0, income: 0 }, 0, 12);
  const monthsArray = _.reduce(
    grouppedByMonths,
    (acc, month, monthNumber) => {
      const monthlyValues = _.mapValues(
        _.reduce(
          month,
          (sum, expense) => {
            sum["name"] = i18n.t(`months.${MONTHS[parseInt(monthNumber) - 1]}`);
            const value = expense.value;
            if (expense.type === ENTRY_TYPES.EXPENSE) {
              sum["expense"] += +value;
            } else {
              sum["income"] += +value;
            }
            return sum;
          },
          { name: "", expense: 0, income: 0 }
        ),
        (value) => {
          return _.isNumber(value) ? value : value;
        }
      );

      //@ts-ignore
      acc[monthNumber - 1] = monthlyValues;
      return acc;
    },
    templateArray
  );

  return monthsArray;
};

export const getPrevEntry = (entries: Partial<IEntry>[], entry: Partial<IEntry>): IEntry => {
  const index = _.findIndex(entries, (e) => e.id === entry.id);
  return entries[index - 1] as IEntry;
};

export const getNextEntry = (entries: Partial<IEntry>[], entry: Partial<IEntry>): IEntry => {
  const index = _.findIndex(entries, (e) => e.id === entry.id);
  return entries[index + 1] as IEntry;
};

export const getEntryGroupKey = (entry: Pick<IEntry, "date">) =>
  parseDate(entry.date).toFormat("yyyy-MM-dd");

export const getNewEntryDate = (prevEntry?: IEntry, nextEntry?: IEntry, currentGroup?: string) => {
  const groupDate = DateTime.fromISO(currentGroup as string);

  if (prevEntry && nextEntry) {
    return parseDate(nextEntry.date)
      .minus({
        millisecond: Math.floor(
          parseDate(nextEntry.date).diff(parseDate(prevEntry.date)).valueOf() / 2
        ),
      })
      .set({
        year: groupDate.get("year"),
        month: groupDate.get("month"),
        day: groupDate.get("day"),
      });
  } else if (prevEntry) {
    return parseDate(prevEntry.date)
      .plus({ second: 1 })
      .set({
        year: groupDate.get("year"),
        month: groupDate.get("month"),
        day: groupDate.get("day"),
      });
  } else if (nextEntry) {
    return parseDate(nextEntry.date)
      .minus({ second: 1 })
      .set({
        year: groupDate.get("year"),
        month: groupDate.get("month"),
        day: groupDate.get("day"),
      });
  }
};
