import React from "react";
import { Position, OverlayToaster } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";

// /** Singleton toaster instance. Create separate instances for different options. */
// export const Notifications =
//   typeof window !== "undefined" &&
//   Toaster.create({
//     className: "recipe-toaster",
//     position: Position.TOP,
//   });

export let Notifications: OverlayToaster;
const container = document.getElementById("toaster");
if (container instanceof HTMLElement) {
  const root = createRoot(document.getElementById("toaster")!);
  root.render(
    <OverlayToaster
      ref={(instance) => {
        Notifications = instance!;
      }}
      position={Position.TOP}
    />
  );
}
