import { Button, Dialog, DialogBody, DialogFooter, Intent } from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";

export type ExportOptions = {
  includeIncomes: boolean;
  includeExpenses: boolean;
  includeValues: boolean;
};

type ExportDialogProps = {
  titleKey?: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onExport: () => void;
};

const ExportDialog = ({
  titleKey = "messages.export",
  isOpen,
  children,
  onClose,
  onExport,
}: ExportDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={isOpen} isCloseButtonShown onClose={onClose} title={t(titleKey)}>
      <DialogBody>{children}</DialogBody>
      <DialogFooter
        actions={
          <React.Fragment>
            <Button intent={Intent.PRIMARY} onClick={() => onExport()}>
              {t("actions.export")}
            </Button>
            <Button intent={Intent.NONE} onClick={onClose}>
              {t("actions.cancel")}
            </Button>
          </React.Fragment>
        }
      ></DialogFooter>
    </Dialog>
  );
};

export default ExportDialog;
