import { ApolloQueryResult, useMutation } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import { DateTime } from "luxon";
import React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { INLINE_UPDATE_ENTRY } from "../../graphql/mutations/entry";
import { ICurrency, IEntry } from "../../types/types";
import { graphQlError } from "../../utils/utils";
import InlineCategoryEdit from "../categories/InlineCategoryEdit";
import Actions from "../common/Actions";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import { Notifications } from "../common/notifications";
import InlineEditablePayee from "../payees/InlineEditablePayee";
import { TColumns } from "./EntriesTable";

export const TableCell = ({
  entry,
  column,
  openDeleteDialog,
  onEdit,
  refetch,
}: {
  entry: IEntry;
  column: TColumns;
  openDeleteDialog: (entry: IEntry) => void;
  onEdit: (entry: IEntry) => void;
  refetch:
    | ((
        variables?:
          | Partial<{
              periodStart: DateTime;
              periodEnd: DateTime;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | (() => Promise<[ApolloQueryResult<any>, ApolloQueryResult<any>]>);
}) => {
  const { t } = useTranslation();
  const [editingCell, setEditingCell] = useState<{
    key: string;
    value: string | number;
  } | null>(null);

  const [updateEntry] = useMutation(INLINE_UPDATE_ENTRY);

  const currency = useMemo(
    () => JSON.parse((entry.account?.currency as ICurrency).toString() || "{}"),
    [entry]
  );

  const update = () => {
    if (!editingCell) {
      setEditingCell(null);
    }
    // @ts-ignore
    (entry[editingCell?.key as string] !== editingCell?.value
      ? updateEntry({
          variables: {
            id: entry.entryId || entry.id,
            changes: {
              [editingCell?.key as string]: editingCell?.value,
            },
          },
        })
      : Promise.resolve(false)
    )
      .then((res) => {
        res &&
          Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      .catch(graphQlError)
      .finally(() => {
        setEditingCell(null);
      });
  };

  switch (column) {
    // case "date":
    //   return <DateComponent date={entry.date} />;
    case "value":
      return <MoneyValue value={entry.value} currency={currency} />;

    case "description":
      return editingCell?.key === "description" ? (
        <textarea
          autoFocus
          value={editingCell.value}
          onBlur={update}
          onChange={(e) =>
            setEditingCell(
              (editingCell) =>
                ({
                  ...editingCell,
                  value: e.target.value,
                } as any)
            )
          }
        ></textarea>
      ) : (
        <div
          className="w-full h-6"
          onClick={() => setEditingCell({ key: "description", value: entry.description })}
        >
          {entry.description}
        </div>
      );
    case "category":
      return <InlineCategoryEdit entry={entry} refetch={refetch} />;
    case "payee":
      return <InlineEditablePayee entry={entry} />;
    case "account":
      return (
        <InternalLink to={`/accounts/${entry.account?.id}`}>{entry.account?.name}</InternalLink>
      );
    case "balance":
      return (
        <InternalLink to={`/accounts/${entry.account?.id}`}>
          <MoneyValue value={entry.balance ?? 0} currency={currency} />
        </InternalLink>
      );
    case "type":
      return <div>{entry.type.toUpperCase()}</div>;
    case "actions":
      return <Actions entry={entry} onDelete={() => openDeleteDialog(entry)} onEdit={onEdit} />;
    default:
      return <div>Unknown cell</div>;
  }
};
