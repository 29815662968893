import React from "react";
import { FetchResult, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, HTMLTable, Icon, Intent, NonIdealState, Text } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { t } from "i18next";
import _ from "lodash";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useCreateAccount, useDeleteAccount } from "../../graphql/hooks/account";
import { UPDATE_ACCOUNT } from "../../graphql/mutations/account";
import { CREATE_CATEGORIES, UPDATE_CATEGORY } from "../../graphql/mutations/category";
import { ACCOUNTS } from "../../graphql/queries/account";
import { CATEGORIES_BY_ACCOUNT_ID } from "../../graphql/queries/categories";
import { pageTitle } from "../../settings";
import { IAccount, ICurrency, IUser } from "../../types/types";
import { getUserSettings } from "../../utils/utils";
import { BudgetContext } from "../WithBudgetContext";
import { UserContext } from "../WithUserContext";
import Actions from "../common/Actions";
import { ConfirmDialog } from "../common/ConfirmDialog";
import DateComponent from "../common/Date";
import GraphQlError from "../common/GraphQlError";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";
import TableHeader from "../common/TableHeader";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import AccountForm from "../forms/AccountForm";

interface IAccountProps {
  account: IAccount;
  showBalanceOnAccountEntryTable: boolean;
  onDelete: (arg0: number) => void;
  onEdit: (arg0: IAccount) => void;
}

export const Account: FC<IAccountProps> = ({
  account,
  onEdit,
  onDelete,
  showBalanceOnAccountEntryTable,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const amount = useMemo(() => {
    return _.first(account.entries)?.balance || 0;
  }, [account]);

  const openDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  return (
    <tr>
      <td>
        <div>
          <Text ellipsize className="flex-grow flex flex-row items-center">
            {account.main_account && <Icon className="mr-2" icon={IconNames.CROWN} />}
            <Icon className="mr-2" icon={IconNames.CREDIT_CARD} />{" "}
            <InternalLink to={`/accounts/${account.id}`}>{account.name}</InternalLink>
          </Text>
        </div>
      </td>
      {showBalanceOnAccountEntryTable && (
        <td className="budget-table-amount">
          <div>
            <Text ellipsize className="flex-grow">
              <MoneyValue value={amount} currency={account.currency as ICurrency} />
            </Text>
          </div>
        </td>
      )}
      <td className="budget-table-actions">
        <Actions entry={account} onEdit={() => onEdit(account)} onDelete={openDeleteDialog} />
        <ConfirmDialog
          icon={IconNames.WARNING_SIGN}
          title={`${t("warnings.delete_account_warning")}`}
          message={
            <div>
              {`${t("warnings.remove_this_account_confirm")}`}
              <div>
                {`${t("accounts.account_name")}`}: <b>{account.name}</b>
              </div>
              <div className="mt-2">{`${t("warnings.following_entries_will_be_removed")}`}</div>
              <div>
                {_.map(account.entries, (entry) => {
                  return (
                    <div key={entry.id} className="flex flex-row">
                      <div className="mr-2">
                        <DateComponent date={entry.date} />
                      </div>
                      <div className="mr-2">
                        <MoneyValue value={entry.value} currency={account.currency as ICurrency} />
                      </div>
                      <div>{entry.category?.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
          onCancel={closeDeleteDialog}
          isOpen={deleteDialogOpen}
          onApply={() => {
            onDelete(account.id as number);
            closeDeleteDialog();
          }}
        />
      </td>
    </tr>
  );
};

export const getAccountInfoResponse = (
  response: FetchResult<any, Record<string, any>, Record<string, any>>
): { data: IAccount | null | undefined; type: "insert" | "update" } => {
  const data = _.get(response, "insert_accounts_one")
    ? _.get(response, "insert_accounts_one")
    : _.get(response, "update_accounts_by_pk")
    ? _.get(response, "update_accounts_by_pk")
    : null;

  const type = _.get(response, "insert_accounts_one") ? "insert" : "update";

  return { data, type };
};

export default function Accounts() {
  const { t } = useTranslation();

  const userData = useContext<IUser | undefined>(UserContext);
  const settings = getUserSettings(userData?.settings || "{}");
  const currentBudget = useContext(BudgetContext);

  const { data, loading, error } = useQuery(ACCOUNTS, {
    variables: { budgetId: currentBudget?.id },
  });

  const [createCategories] = useMutation(CREATE_CATEGORIES);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const [updateAccount] = useMutation(UPDATE_ACCOUNT);
  const client = useApolloClient();
  const [createAccount] = useCreateAccount();
  const [deleteAccount] = useDeleteAccount();

  const [accountEntry, setAccountEntry] = useState<IAccount | null>(null);

  const onEdit = (account: IAccount) => setAccountEntry(account);
  const onDelete = (accountId: number) => deleteAccount({ variables: { id: accountId } });
  const createNewAccount = () =>
    setAccountEntry({ name: "", initial_amount: 0, main_account: false });
  const closeDialog = () => setAccountEntry(null);

  const updateCategories = (account: IAccount) => {
    return client
      .query({
        query: CATEGORIES_BY_ACCOUNT_ID,
        variables: {
          accountId: account.id,
          budgetId: currentBudget?.id,
        },
      })
      .then((res) => {
        const { categories } = res.data;
        const newCats = _.map(categories, (category) => {
          return _.omit(
            _.extend({}, category, {
              name: `${category.name.split(":")[0]}: ${account.name}`,
            }),
            "__typename"
          );
        });
        return Promise.all(
          _.map(newCats, (cat) => updateCategory({ variables: { id: cat.id, name: cat.name } }))
        );
      });
  };

  const onSave = (account: IAccount) => {
    return (
      (
        !account.id
          ? createAccount({
              variables: {
                object: {
                  ..._.omit(account, ["__typename", "currency"]),
                  budget_id: currentBudget?.id,
                  user_id: userData?.user_id,
                  currency: JSON.stringify(account.currency),
                },
              },
            })
          : updateAccount({
              variables: {
                ..._.omit(account, ["__typename", "currency"]),
                currency: JSON.stringify(account.currency),
              },
            })
      )
        // @ts-ignore
        .then(({ data }) => {
          const { data: response, type } = getAccountInfoResponse(data);
          if (!response) {
            //TODO throw and error here, or handle it somehow
            return null;
          }
          const categories = [
            {
              name: `Transfer to: ${response.name} account`,
              budget_id: currentBudget?.id,
              user_id: userData?.user_id,
              parent_account: response.id,
            },
            {
              name: `Transfer from: ${response.name} account`,
              budget_id: currentBudget?.id,
              user_id: userData?.user_id,
              parent_account: response.id,
            },
          ];
          return response.id && type === "insert"
            ? createCategories({ variables: { objects: categories } })
            : updateCategories(response);
        })
        .then(closeDialog)
    );
  };

  const onChange = (key: string, value: any): void => {
    setAccountEntry(_.extend({}, accountEntry, { [key]: value }));
  };
  const { accounts = [] } = data || {};

  const parsedAccounts = useMemo(
    () =>
      _.map(accounts, (account) =>
        _.extend({}, account, { currency: JSON.parse(account.currency) })
      ),
    [accounts]
  );

  const showBalanceOnAccountEntryTable = Boolean(settings.showBalanceOnAccountEntryTable);

  return (
    <WithLoadingSpinner isLoading={loading}>
      <Helmet>
        <title>Accounts - {pageTitle}</title>
      </Helmet>
      <GraphQlError error={error}>
        <Dialog
          usePortal
          isOpen={!_.isEmpty(accountEntry)}
          onClose={closeDialog}
          title={
            accountEntry?.id
              ? t("accounts.edit_account", { name: accountEntry?.name })
              : t("accounts.new_account")
          }
        >
          <AccountForm
            account={accountEntry as IAccount}
            onChange={onChange}
            onApply={onSave}
            onCancel={closeDialog}
          />
        </Dialog>
        {_.isEmpty(accounts) ? (
          <NonIdealState
            icon="search"
            title={t("accounts.no_accounts")}
            action={
              <Button
                text={t("accounts.new_account")}
                intent={Intent.PRIMARY}
                onClick={createNewAccount}
              />
            }
          />
        ) : (
          <div>
            <TableHeader
              rightElement={
                <Button
                  icon={IconNames.ADD}
                  text={t("accounts.new_account")}
                  onClick={createNewAccount}
                />
              }
              leftElement={<div></div>}
            />

            <HTMLTable
              bordered
              compact
              striped
              className="entries-table w-full max-w-full table-fixed dark:text-gray-100"
            >
              <thead>
                <tr>
                  <th>{t("labels.account")}</th>
                  {showBalanceOnAccountEntryTable && (
                    <th className="budget-table-amount">
                      <div>{t("labels.balance")}</div>
                    </th>
                  )}
                  <th className="budget-table-actions" />
                </tr>
              </thead>
              <tbody>
                {_.map(parsedAccounts, (account) => (
                  <Account
                    key={account.id}
                    account={account}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    showBalanceOnAccountEntryTable={showBalanceOnAccountEntryTable}
                  />
                ))}
              </tbody>
            </HTMLTable>
          </div>
        )}
      </GraphQlError>
    </WithLoadingSpinner>
  );
}
