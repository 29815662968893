import { useMutation } from "@apollo/client";
import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { INLINE_UPDATE_ENTRY } from "../../graphql/mutations/entry";
import { IEntry, IPayee } from "../../types/types";
import { graphQlError } from "../../utils/utils";
import InternalLink from "../common/InternalLink";
import PayeeSelect from "../common/PayeeSelect";
import { Notifications } from "../common/notifications";
import React from "react";

type Props = {
  entry: IEntry;
};

const InlineEditablePayee = ({ entry }: Props) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState<IPayee | null>(null);

  const [updateEntry] = useMutation(INLINE_UPDATE_ENTRY);

  const onChangePayee = (payee: IPayee) => {
    updateEntry({
      variables: {
        id: entry.entryId || entry.id,
        changes: {
          payee_id: payee.id,
        },
      },
    })
      .then((res) => {
        res &&
          Notifications &&
          Notifications.show({ message: t("messages.updated"), intent: Intent.SUCCESS });
      })
      .catch(graphQlError)
      .finally(closeEditing);
  };

  const closeEditing = useCallback(() => {
    setEditing(null);
  }, []);

  return editing ? (
    <div className="with-inline-edit-icon flex flex-row">
      <PayeeSelect
        fill={true}
        disabled={false}
        onChange={onChangePayee}
        initialPayee={entry.payee as IPayee}
      />
      <Icon className="inline-edit-icon" icon={IconNames.CROSS} onClick={closeEditing} />
    </div>
  ) : (
    <div className="with-inline-edit-icon flex flex-row">
      <div className="flex-1">
        <InternalLink to={`/payees/${entry.payee?.id}`}>{entry.payee?.name}</InternalLink>
      </div>
      <Icon
        className="inline-edit-icon"
        icon={IconNames.EDIT}
        onClick={() => setEditing((entry.payee || {}) as IPayee)}
      />
    </div>
  );
};

export default InlineEditablePayee;
