import { useMutation } from "@apollo/client";
import React from "react";

import {
  Button,
  ButtonGroup,
  Callout,
  Dialog,
  DialogBody,
  DialogFooter,
  Icon,
  Intent,
  Switch,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SOFT_DELETE_BUDGET } from "../../graphql/mutations/budget";
import { UPDATE_USER_SETTINGS } from "../../graphql/mutations/user";
import { USER } from "../../graphql/queries/user";
import { ICurrency, IUser } from "../../types/types";
import {
  getUserSettings,
  graphQlError,
  isTestEnv,
  sendMessage,
  updateUserSettingsObject,
} from "../../utils/utils";
import CurrencySelect from "../common/CurrencySelect";
import InternalLink from "../common/InternalLink";
import { Notifications } from "../common/notifications";
import ContentWithFooter from "../layouts/ContentWithFooter";
import Subscriptions from "../Subscriptions";
import { useSubscriptionData } from "../useSubscriptionData";
import { UserContext } from "../WithUserContext";

const SWITCH_SETTINGS = ["showDarkModeSwitch", "showBalanceOnAccountEntryTable"];

export default function Settings() {
  const userData = useContext<IUser | undefined>(UserContext);

  const [showDeleteBudgetConfirmation, setShowDeleteBudgetConfirmation] =
    React.useState<boolean>(false);

  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] =
    React.useState<boolean>(false);

  const [showCancelSubscriptionConfirmation, setShowCancelSubscriptionConfirmation] =
    React.useState<boolean>(false);

  const subscriptionData = useSubscriptionData();
  const settings = getUserSettings(userData?.settings || "{}");
  const { t } = useTranslation();
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS, {
    refetchQueries: [{ query: USER, variables: { userId: userData?.user_id } }],
  });

  const [softDeleteBudget] = useMutation(SOFT_DELETE_BUDGET, {
    variables: { id: _.first(userData?.budgets)?.id },
    refetchQueries: [{ query: USER, variables: { userId: userData?.user_id } }],
  });

  const update = (key: string, value: string | boolean | number | Record<string, any>) => {
    updateUserSettings({
      variables: {
        id: userData?.user_id,
        settings: JSON.stringify(updateUserSettingsObject(settings, key, value)),
      },
    })
      .then(() => {
        Notifications &&
          Notifications.show({ message: t("messages.updated") as string, intent: Intent.SUCCESS });
      })
      .catch(graphQlError);
  };

  const handleSwitch = (key: string) => (evt: React.FormEvent<HTMLInputElement>) => {
    update(key, evt.currentTarget.checked);
  };

  const deleteUser = () =>
    sendMessage({
      userData: userData as IUser,
      name: "REMOVE USER ACCOUNT REQUEST",
      message: "User has requested his account to be deleted. PROCEEDE ASAP",
      t,
      onSuccess: () => null,
      onFail: () => null,
      onFinally: () => {
        setShowDeleteAccountConfirmation(false);
      },
    });

  const cancelSubscription = () =>
    sendMessage({
      userData: userData as IUser,
      name: "CANCEL USER SUBSCRIPTION REQUEST",
      message: "User has requested his subscriptions to be cancelled. PROCEEDE ASAP",
      t,
      onSuccess: () => null,
      onFail: () => null,
      onFinally: () => {
        setShowCancelSubscriptionConfirmation(false);
      },
    });

  const deleteBudget = () => {
    softDeleteBudget()
      .then(() => {
        Notifications &&
          Notifications.show({
            message: t("messages.budget_deleted") as string,
            intent: Intent.SUCCESS,
          });
      })
      .catch(graphQlError)
      .finally(() => {
        setShowDeleteBudgetConfirmation(false);
      });
  };

  console.log({ subscriptionData });
  const haveBudget = userData?.budgets?.length && userData?.budgets.length > 0;
  return (
    <ContentWithFooter>
      <Subscriptions />
      <Callout className="mb-4" title={t("labels.settings")} icon={IconNames.COG}>
        {isTestEnv() && (
          <div className="my-2">
            <pre>{JSON.stringify(settings, null, 2)}</pre>
          </div>
        )}
        <div className="my-2">
          {userData?.role === "super_admin" && (
            <div className="flex flex-row items-center">
              <div className="font-semibold mr-5 -mt-2">
                {t("labels.premium_features") as string}
              </div>
              <Switch
                checked={settings.premiumFeatures as boolean}
                onChange={handleSwitch("premiumFeatures")}
              />
            </div>
          )}
          {_.map(SWITCH_SETTINGS, (setting) => {
            return (
              <div className="flex flex-row items-center" key={setting}>
                <div className="font-semibold mr-5 -mt-2">
                  {t(`labels.${_.snakeCase(setting)}`) as string}
                </div>
                <Switch checked={settings[setting] as boolean} onChange={handleSwitch(setting)} />
              </div>
            );
          })}

          <div className="flex flex-row items-center">
            <div className="font-semibold mr-5">{t("labels.currency") as string}</div>
            <CurrencySelect
              selected={settings.currency as ICurrency}
              onItemSelect={(selectedCurrency: any) => update("currency", selectedCurrency)}
            />
          </div>
        </div>
      </Callout>
      <Callout
        className="mt-4"
        intent={haveBudget ? Intent.DANGER : Intent.PRIMARY}
        title={t("labels.current_budget")}
        icon={IconNames.DOLLAR}
      >
        <div className="my-2">
          {haveBudget ? (
            <Button
              intent={Intent.DANGER}
              title={t("actions.delete_budget")}
              onClick={() => setShowDeleteBudgetConfirmation(true)}
            >
              {t("actions.delete_budget")}
            </Button>
          ) : (
            <InternalLink to="/budgets/new" className="bp5-button bp5-intent-primary">
              <span className="inline-block pl-2 pr-4">
                <Icon icon={IconNames.MAP_CREATE} />
              </span>
              {t("actions.create_budget")}
            </InternalLink>
          )}
        </div>
      </Callout>
      <Callout
        className="my-4"
        title={t("labels.subscription")}
        intent={
          subscriptionData.hasValidSubscription
            ? subscriptionData.status === "trialing"
              ? Intent.WARNING
              : Intent.SUCCESS
            : Intent.DANGER
        }
      >
        <div className="my-2">
          <div className="mt-2">
            {t(`labels.${subscriptionData.status || "no_subscription_found"}`) as string}
          </div>
        </div>
        <div className="my-2">
          {subscriptionData.status === "active" && (
            <Button
              intent={Intent.DANGER}
              title={t("actions.cancel_subscription")}
              onClick={() => setShowCancelSubscriptionConfirmation(true)}
            >
              {t("actions.cancel_subscription")}
            </Button>
          )}
        </div>
      </Callout>
      <Callout
        className="mt-4"
        intent={Intent.DANGER}
        title={t("labels.account")}
        icon={IconNames.USER}
      >
        <div className="my-2">
          <Button
            intent={Intent.DANGER}
            title={t("actions.delete_account")}
            onClick={() => setShowDeleteAccountConfirmation(true)}
          >
            {t("actions.delete_account")}
          </Button>
        </div>
      </Callout>

      <DeleteBudgetDialog
        isOpen={showDeleteBudgetConfirmation}
        onClose={() => setShowDeleteBudgetConfirmation(false)}
        onAccept={deleteBudget}
      />

      <DeleteAccountDialog
        isOpen={showDeleteAccountConfirmation}
        onClose={() => setShowDeleteAccountConfirmation(false)}
        onAccept={deleteUser}
      />

      <CancelSubscriptionDialog
        isOpen={showCancelSubscriptionConfirmation}
        onClose={() => setShowCancelSubscriptionConfirmation(false)}
        onAccept={cancelSubscription}
      />
    </ContentWithFooter>
  );
}

const DeleteBudgetDialog = ({
  isOpen,
  onClose,
  onAccept,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("confirm.delete_budget.title")}
      icon={IconNames.USER}
    >
      <DialogBody>
        <div className="my-2">
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: t("confirm.delete_budget.message") as string }}
          />
        </div>
      </DialogBody>
      <DialogFooter
        actions={
          <ButtonGroup>
            <Button onClick={onClose}>{t("actions.cancel")}</Button>
            <Button intent={Intent.DANGER} onClick={onAccept}>
              {t("actions.delete")}
            </Button>
          </ButtonGroup>
        }
      ></DialogFooter>
    </Dialog>
  );
};

const DeleteAccountDialog = ({
  isOpen,
  onClose,
  onAccept,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("confirm.delete_account.title")}
      icon={IconNames.USER}
    >
      <DialogBody>
        <div className="my-2">
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: t("confirm.delete_account.message") as string }}
          />
        </div>
      </DialogBody>
      <DialogFooter
        actions={
          <ButtonGroup>
            <Button onClick={onClose}>{t("actions.cancel")}</Button>
            <Button intent={Intent.DANGER} onClick={onAccept}>
              {t("actions.delete")}
            </Button>
          </ButtonGroup>
        }
      ></DialogFooter>
    </Dialog>
  );
};

const CancelSubscriptionDialog = ({
  isOpen,
  onClose,
  onAccept,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("confirm.cancel_subscription.title")}
      icon={IconNames.USER}
    >
      <DialogBody>
        <div className="my-2">
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: t("confirm.cancel_subscription.message") as string }}
          />
        </div>
      </DialogBody>
      <DialogFooter
        actions={
          <ButtonGroup>
            <Button onClick={onClose}>{t("actions.cancel")}</Button>
            <Button intent={Intent.DANGER} onClick={onAccept}>
              {t("actions.delete")}
            </Button>
          </ButtonGroup>
        }
      ></DialogFooter>
    </Dialog>
  );
};
