import { useQuery } from "@apollo/client";
import { Icon, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import React from "react";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { PAYEE, PAYEES } from "../../graphql/queries/payees";
import useSelectedDate from "../../hooks/useSelectedDate";
import { IPayee } from "../../types/types";
import InternalLink from "../common/InternalLink";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import { EntriesTable } from "../entries/Entries";

export default function Payee(): ReactElement {
  const { payeeId } = useParams<{ payeeId: string }>();

  const {
    selectedDate,
    setCurrentMonthAsSelectedDate,
    setPrevMonthAsSelectedDate,
    setNextMonthAsSelectedDate,
  } = useSelectedDate();

  const { data, loading, error, refetch } = useQuery(PAYEE, {
    variables: {
      payeeId: parseInt(payeeId || "0"),
      periodStart: selectedDate.startOf("month"),
      periodEnd: selectedDate.endOf("month"),
    },
  });
  const { payees_by_pk: payee } = data || {};

  return (
    <WithLoadingSpinner isLoading={loading}>
      <EntriesTable
        currentPage="payee"
        loading={loading}
        columns={["value", "account", "category", "description", "actions"]}
        entries={payee?.entries}
        entrySwitcher={<PayeeSelect currentPayee={payee} />}
        selectedDate={selectedDate}
        setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
        setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
        setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
        refetch={refetch}
        initialForm={{ payee }}
      />
    </WithLoadingSpinner>
  );
}

const PayeeSelect = ({ currentPayee }: { currentPayee: IPayee }) => {
  const { data, loading: loadingPayees } = useQuery(PAYEES);

  const { payees } = data || {};

  return (
    <WithLoadingSpinner isLoading={loadingPayees}>
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <Menu>
            {_.map(payees || [], (acc) => (
              <MenuItem
                icon={IconNames.CREDIT_CARD}
                key={acc.id}
                tagName="div"
                text={<InternalLink to={`/payees/${acc.id}`}>{acc.name}</InternalLink>}
              />
            ))}
          </Menu>
        }
      >
        <div className="flex flex-row items-center cursor-pointer">
          <Icon icon={IconNames.CREDIT_CARD} /> <div className="mx-2">{currentPayee?.name} </div>
          <Icon icon={IconNames.CHEVRON_DOWN} />
        </div>
      </Popover>
    </WithLoadingSpinner>
  );
};
