import { useTranslation } from "react-i18next";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { ContactForm } from "./common/ContactForm";
import { pageTitle } from "../settings";
import { Helmet } from "react-helmet";
import React from "react";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContentWithFooter>
      <Helmet>
        <title>Contact - {pageTitle}</title>
      </Helmet>
      <h2 className="bp5-heading pt-12 pb-6 text-center">{t("labels.contact_us")}</h2>
      <ContactForm />
    </ContentWithFooter>
  );
};

export default Contact;
