import { FormGroup, Icon, Intent, Tooltip } from "@blueprintjs/core";
import { Trans, useTranslation } from "react-i18next";
import { ContactForm } from "./common/ContactForm";
import ContentWithFooter from "./layouts/ContentWithFooter";
import { IconNames } from "@blueprintjs/icons";
import { Helmet } from "react-helmet";
import { pageTitle } from "../settings";
import React from "react";

const ROADMAP_LIST = [
  {
    name: "mobile_app",
    status: "todo",
    intent: Intent.NONE,
    icon: IconNames.FLAG,
  },
  {
    name: "design_improve",
    status: "todo",
    intent: Intent.NONE,
    icon: IconNames.FLAG,
  },
  {
    name: "excel_reports",
    status: "todo",
    intent: Intent.NONE,
    icon: IconNames.FLAG,
  },
];

const Roadmap = () => {
  const { t } = useTranslation();
  return (
    <ContentWithFooter>
      <Helmet>
        <title>Roadmap - {pageTitle}</title>
      </Helmet>
      <div className="flex flex-col md:flex-row max-w-7xl mx-auto">
        <div className="flex-1">
          <h2 className="bp5-heading pt-12 pb-6 text-center">{t("labels.project_roadmap")}</h2>
          <ul className="text-lg mb-2">
            {ROADMAP_LIST.map((item) => (
              <li key={item.name} className="mb-4">
                <Tooltip content={t(`roadmap.${item.status}`)} intent={item.intent}>
                  <div>
                    <Icon size={30} icon={item.icon} intent={item.intent} className="mr-2" />{" "}
                    {t(`roadmap.${item.name}`)}
                  </div>
                </Tooltip>
              </li>
            ))}
            <li>{t("roadmap.anything_you_want_to_add")}</li>
          </ul>
        </div>
        <div className="flex-1">
          <div>
            <h2 className="bp5-heading pt-12 pb-6 text-center">{t("labels.feedback")}</h2>
            <FormGroup
              helperText={
                <div className="text-center">
                  <Trans i18nKey="feedback.text">
                    <strong className="block">
                      Please take a moment to share your thoughts and suggestions with us.
                    </strong>
                    Your feedback helps us improve our services and provide a better experience for
                    you.
                  </Trans>
                </div>
              }
            ></FormGroup>
          </div>
          <ContactForm />
        </div>
      </div>
    </ContentWithFooter>
  );
};

export default Roadmap;
