import { Callout, Classes } from "@blueprintjs/core";
import { t } from "i18next";
import { DateTime } from "luxon";
import { IBudgetEntry } from "../../types/types";
import React from "react";
import {
  calculateSpendings,
  getEntriesToBeDisplayedOnBudget,
  useGetStylesForRows,
} from "../../utils/budgetUtils";
import Actions from "../common/Actions";
import InternalLink from "../common/InternalLink";
import MoneyValue from "../common/MoneyValue";

type Props = {
  budgetEntry: IBudgetEntry;
  selectedDate: DateTime;
  onDelete: (budgetEntry: IBudgetEntry) => void;
  onEdit: (budgetEntry: IBudgetEntry) => void;
};

const MobileBudgetEntry = ({ budgetEntry, selectedDate, onDelete, onEdit }: Props) => {
  const entries = getEntriesToBeDisplayedOnBudget(budgetEntry, selectedDate);
  const spendings = calculateSpendings(entries);
  const { className, styles } = useGetStylesForRows(budgetEntry.value, spendings);

  return (
    <Callout className={`mb-1 ${className}`} style={styles}>
      <div>
        <div className="flex flex-row">
          <h5 className={`${Classes.HEADING} flex-grow`}>
            <InternalLink to={`/categories/${budgetEntry.category?.id}`}>
              {t("labels.category") as string}: {budgetEntry.category?.name}
            </InternalLink>
          </h5>
          <Actions entry={budgetEntry} onDelete={onDelete} onEdit={onEdit} />
        </div>
        <div className="flex flex-row">
          <span className="inline-block mr-2">{t("labels.planned") as string}: </span>
          <MoneyValue value={budgetEntry.value} />
        </div>
        <div className="flex flex-row">
          <span className="inline-block mr-2">{t("labels.spendings") as string}: </span>
          <MoneyValue value={spendings} />
        </div>
      </div>
    </Callout>
  );
};

export default MobileBudgetEntry;
