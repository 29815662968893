import _ from "lodash";
import { DateTime } from "luxon";
import {
  IBudgetEntry,
  IBudgetSection,
  ICategory,
  IEntry,
  IIncome,
  ISingleEntry,
  ISplittedEntry,
} from "../types/types";
import { COLOR_GRADIENT_RANGE, ENTRY_TYPES } from "./constants";
import { getContrast, getNormalizedValue0ToRange, parseDate } from "./utils";

import { useNegativeColorsGradient, usePositiveColorsGradient } from "../stores/useColorsGradient";
import { useEffect, useMemo } from "react";

export const getThisMonthIncomes = (incomes: IIncome[], endCurrentMonth: DateTime): IIncome[] => {
  return !_.isEmpty(incomes)
    ? _.filter(incomes, (income) => {
        return income.deleted_at
          ? endCurrentMonth < parseDate(income.deleted_at) &&
              endCurrentMonth > parseDate(income.income_date)
          : endCurrentMonth > parseDate(income.income_date);
      })
    : [];
};

export const getTotalIncome = (thisMonthIncomes: IIncome[]): number =>
  !_.isEmpty(thisMonthIncomes)
    ? _.reduce(thisMonthIncomes, (acc, income) => (acc += income.value), 0)
    : 0;

export const getTotalRealIncome = (thisMonthIncomes: IIncome[]): number =>
  !_.isEmpty(thisMonthIncomes)
    ? _.reduce(thisMonthIncomes, (acc, income) => (acc += income.actual_value), 0)
    : 0;

export const getThisMonthBudgetEntries = (
  budget_entries: IBudgetEntry[],
  endCurrentMonth: DateTime
): IBudgetEntry[] =>
  _.filter(budget_entries, (budgetEntry) => {
    return budgetEntry.deleted_at
      ? endCurrentMonth < parseDate(budgetEntry.deleted_at) &&
          endCurrentMonth > parseDate(budgetEntry.date)
      : endCurrentMonth > parseDate(budgetEntry.date);
  });

export const getTotalSpendingsFromEntries = (entries: IEntry[] = []): number => {
  return _.reduce(
    entries,
    (acc, entry) => {
      return entry.type === ENTRY_TYPES.EXPENSE ? (acc += entry.value) : (acc -= entry.value);
    },
    0
  );
};

export const getTotalPlannedSpendingsInCurrentMonth = (
  thisMonthBudgetEntries: IBudgetEntry[]
): number => {
  return _.reduce(thisMonthBudgetEntries, (acc, { value }) => (acc += value), 0);
};

export const getBindedCategoriesIds = (budget_sections: IBudgetSection[]) => {
  return _.chain(budget_sections)
    .map("budget_entries")
    .flatten()
    .map("category")
    .map("id")
    .compact()
    .uniq()
    .value();
};

export const getUnbindedCategories = (categories: ICategory[], bindedCategoriesIds: number[]) => {
  return _.filter(
    categories,
    (category) =>
      !_.includes(bindedCategoriesIds, category.id) &&
      !_.includes(category.name, "Transfer") &&
      category.category_type === ENTRY_TYPES.EXPENSE
  );
};

export const calculateSpendings = (entries: Partial<IEntry>[]): number => {
  return _.reduce(
    entries,
    (acc: number, entry) => {
      return (entry as IEntry).type === ENTRY_TYPES.EXPENSE
        ? (acc += (entry as IEntry).value)
        : (acc -= (entry as IEntry).value);
    },
    0
  );
};

export const getEntriesToBeDisplayedOnBudget = (
  budgetEntry: IBudgetEntry,
  selectedDate: DateTime
): Partial<IEntry>[] | [] => {
  return [
    ...(filterEntriesForCurrentMonth(budgetEntry.category.entries || [], selectedDate) as IEntry[]),
    ...(filterEntriesForCurrentMonth(
      _.map(
        budgetEntry.category.splitted_entries || [],
        (splittedEntry: ISplittedEntry): ISplittedEntry => {
          return {
            ..._.omit(splittedEntry, "entry"),
            ...splittedEntry.entry,
          };
        }
      ),
      selectedDate
    ) as IEntry[]),
  ];
};

export const calculateTotalRealValue = (
  budgetEntries: IBudgetEntry[],
  selectedDate: DateTime
): number => {
  return _.reduce(
    budgetEntries,
    (acc, budgetEntry) => {
      const regularEntries = filterEntriesForCurrentMonth(
        budgetEntry.category.entries || [],
        selectedDate
      );
      const splittedEntries: IEntry[] = budgetEntry.category.splitted_entries
        ? _.map(budgetEntry.category.splitted_entries, (splittedEntry) => {
            return {
              // @ts-ignore
              ..._.omit(splittedEntry, "entry"),
              // @ts-ignore
              ...splittedEntry.entry,
            } as IEntry;
          })
        : [];

      const entValue = _.reduce(
        filterEntriesForCurrentMonth([...regularEntries, ...splittedEntries], selectedDate),
        (acc2, entry) =>
          entry.type === ENTRY_TYPES.EXPENSE ? (acc2 += entry.value) : (acc2 -= entry.value),
        0
      );
      return (acc += entValue);
    },
    0
  );
};

export const filterBudgetEntriesForCurrentMonth = (
  budgetEntries: IBudgetEntry[],
  selectedDate: DateTime
): IBudgetEntry[] => {
  const endCurrentMonth = selectedDate.endOf("month");

  return _.filter(budgetEntries, (budgetEntry) => {
    return budgetEntry.deleted_at
      ? endCurrentMonth < parseDate(budgetEntry.deleted_at) &&
          endCurrentMonth > parseDate(budgetEntry.date)
      : endCurrentMonth > parseDate(budgetEntry.date);
  });
};

export const useGetStylesForRows = (value: number, spendings: number) => {
  const colorsPosGradient = usePositiveColorsGradient((state) => state.gradientArray);
  const colorsNegGradient = useNegativeColorsGradient((state) => state.gradientArray);
  const maxNegValue = useNegativeColorsGradient((state) => state.maxValue);
  const setMaxNegValue = useNegativeColorsGradient((state) => state.setMaxValue);

  useEffect(() => {
    const diff = value - spendings;
    if (diff < 0) {
      if (maxNegValue > diff) {
        setMaxNegValue(diff);
      }
    }
  }, [value, spendings, maxNegValue, setMaxNegValue]);

  const normalizedValue = useMemo<number>(() => {
    if (value - spendings < 0) {
      // negative colors
      return Math.floor(
        getNormalizedValue0ToRange(
          Math.abs(value - spendings),
          Math.abs(maxNegValue),
          COLOR_GRADIENT_RANGE
        )
      );
    } else {
      // positive colors
      // get percentage value of difference
      return Math.floor(
        getNormalizedValue0ToRange(((value - spendings) / value) * 100, 100, COLOR_GRADIENT_RANGE)
      );
    }
  }, [spendings, value, maxNegValue]);

  const { className, styles } = useMemo(() => {
    const color =
      value === spendings || Math.abs(value - spendings) < (2 * value) / 100
        ? "#004D46"
        : value - spendings < 0
        ? colorsNegGradient[_.clamp(normalizedValue - 1, 0, 100)]
        : colorsPosGradient[_.clamp(normalizedValue - 1, 0, 100)];

    return {
      className: color ? getContrast(`${color}`) : "black",
      styles: {
        color: color ? getContrast(`${color}`) : "#000",
        backgroundColor: color ? color : "#fff",
      },
    };
  }, [normalizedValue, value, spendings, colorsNegGradient, colorsPosGradient]);

  return { className, styles };
};

export const alreadyIncludedCategories = (
  budgetSections: IBudgetSection[],
  selectedDate: DateTime
): number[] => {
  const t = _.map(budgetSections, (budgetSection) => {
    const budgetEntries = filterBudgetEntriesForCurrentMonth(
      budgetSection.budget_entries,
      selectedDate
    );
    return _.map(budgetEntries, (budgetEntry) => _.get(budgetEntry, "category.id"));
  });
  return _.uniq(_.flatten(t)) as number[];
};

export const randomIntFromInterval = (min: number, max: number): number => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const filterBySearchQuery = (entries: IEntry[], searchQuery: string): IEntry[] => {
  const lkSearchQuery = searchQuery.toLowerCase();
  if (_.isEmpty(searchQuery)) {
    return entries;
  }
  return _.filter(entries, (entry) => {
    return (
      (entry.description || "")?.toLowerCase().indexOf(lkSearchQuery) !== -1 ||
      `${entry.value}`.toString().indexOf(lkSearchQuery) !== -1 ||
      _.some(entry.splitted_entries, (splittedEntry) => {
        return (
          (splittedEntry.category?.name || "").toLowerCase().indexOf(lkSearchQuery) !== -1 ||
          `${splittedEntry.value}`.toString().indexOf(lkSearchQuery) !== -1
        );
      }) ||
      (entry.category?.name || "").toLowerCase().indexOf(lkSearchQuery) !== -1 ||
      (entry.account?.name || "").toLowerCase().indexOf(lkSearchQuery) !== -1
    );
  });
};

export const filterEntriesForCurrentMonth = (
  entries: Partial<IEntry>[] | Partial<ISplittedEntry>[],
  selectedDate: DateTime,
  searchQuery = ""
): IEntry[] =>
  _.filter(entries, (entry: IEntry | ISplittedEntry) => {
    return (
      selectedDate.get("month") === parseDate(entry.date).get("month") &&
      selectedDate.get("year") === parseDate(entry.date).get("year") &&
      (!_.isEmpty(searchQuery)
        ? entry.description?.toLowerCase().indexOf(searchQuery) !== -1 ||
          `${entry.value}`.toString().indexOf(searchQuery) !== -1 ||
          entry.category?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          entry.account?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        : true)
    );
  }) as IEntry[];

export const prepareSplittedEntries = (
  splittedEntries: ISingleEntry[],
  user_id: number,
  entry_id: number
) => {
  return _.map(splittedEntries, (entry) => {
    return {
      ..._.omit(entry, "__typename", "category", "value"),
      entry_id,
      user_id,
      value: parseFloat(entry.value.toString()),
      category_id: entry.category?.id,
    };
  });
};

export function replaceLastOccurrenceInString(input: string, find: string, replaceWith: string) {
  if (!_.isString(input) || !_.isString(find) || !_.isString(replaceWith)) {
    // returns input on invalid arguments
    return input;
  }

  const lastIndex = input.lastIndexOf(find);
  if (lastIndex < 0) {
    return input;
  }

  return input.substr(0, lastIndex) + replaceWith + input.substr(lastIndex + find.length);
}

export const prepareEntryForInsert = (entry: IEntry, accountId?: number, categoryId?: number) => {
  return {
    ..._.omit(entry, ["id", "__typename", "category", "account", "payee", "payees", "entryId"]),
    category_id: categoryId,
    account_id: accountId,
    payee_id: entry.payee?.id,
    value: entry.value,
    type: entry.type,
    date: entry.date,
    related_entry: entry.entryId,
    splitted_entries: {
      data: entry.splitted_entries
        ? //@ts-ignore
          prepareSplittedEntries(entry.splitted_entries, entry.user_id)
        : [],
      on_conflict: {
        constraint: "splitted_entries_pkey",
        update_columns: ["value", "category_id"],
      },
    },
  };
};
