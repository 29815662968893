import * as Sentry from "@sentry/react";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Changelog from "./components/Changelog";
import Contact from "./components/Contact";
import Pricing from "./components/Pricing";
import PrivateRoute from "./components/PrivateRoute";
import Roadmap from "./components/Roadmap";
import WithBudgetContext from "./components/WithBudgetContext";
import WithToken from "./components/WithToken";
import Account from "./components/accounts/Account";
import Accounts from "./components/accounts/Accounts";
import Dashboard from "./components/budget/Dashboard";
import Categories from "./components/categories/Categories";
import Category from "./components/categories/Category";
import GeneralErrorBoundary from "./components/common/GeneralErrorBoundary";
import InternalLink from "./components/common/InternalLink";
import Entries from "./components/entries/Entries";
import Estimate from "./components/estimates/Estimate";
import Estimates from "./components/estimates/Estimates";
import Goal from "./components/goals/Goal";
import Goals from "./components/goals/Goals";
import DashboardHeader from "./components/header/DashboardHeader";
import PageContent from "./components/layouts/PageContent";
import Payee from "./components/payees/Payee";
import Payees from "./components/payees/Payees";
import Settings from "./components/user/Settings";
import { pageTitle } from "./settings";
import { isTestEnv, isWebViewFromAndroid } from "./utils/utils";

const AppRouter = () => {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <div className={`${isTestEnv() ? "test-env" : ""}`}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {isTestEnv() && (
          <div className="p-1 md:p-4 bg-yellow-200 h-12">
            THIS IS TEST ENVIRONMENT, go to production ready app{" "}
            <a href="https://budget4you.pl">here</a>
          </div>
        )}
        <Router>
          <WithToken>
            <DashboardHeader />
            <PageContent>
              <GeneralErrorBoundary location={window.location}>
                <Routes>
                  <Route path="/changelog" element={<Changelog />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/roadmap" element={<Roadmap />} />
                  <Route path="/settings" element={<PrivateRoute component={Settings} />} />
                </Routes>
                <WithBudgetContext>
                  <Routes>
                    <Route path="/" element={<PrivateRoute component={Dashboard} />} />
                    <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
                    <Route path="/goals" element={<PrivateRoute component={Goals} />} />
                    <Route path="/goals/:goalId" element={<PrivateRoute component={Goal} />} />
                    <Route
                      path="/accounts/:accountId"
                      element={<PrivateRoute component={Account} />}
                    />
                    <Route path="/accounts" element={<PrivateRoute component={Accounts} />} />
                    <Route path="/payees" element={<PrivateRoute component={Payees} />} />
                    <Route path="/payees/:payeeId" element={<PrivateRoute component={Payee} />} />
                    <Route path="/entries" element={<PrivateRoute component={Entries} />} />
                    <Route path="/estimates" element={<PrivateRoute component={Estimates} />} />
                    <Route
                      path="/estimates/:estimateId"
                      element={<PrivateRoute component={Estimate} />}
                    />
                    <Route path="/categories" element={<PrivateRoute component={Categories} />} />
                    <Route
                      path="/categories/:categoryId"
                      element={<PrivateRoute component={Category} />}
                    />
                  </Routes>
                </WithBudgetContext>
              </GeneralErrorBoundary>
            </PageContent>
          </WithToken>
          {!isWebViewFromAndroid() && (
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="cookiesConsent"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              <div>
                This website uses cookies for analytical purposes. By using our website you consent
                to all cookies in accordance with our Cookie Policy.{" "}
                <InternalLink to="/cookies">Read more</InternalLink>
              </div>
            </CookieConsent>
          )}
        </Router>
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default AppRouter;
