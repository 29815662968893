import { ApolloQueryResult } from "@apollo/client";
import _ from "lodash";
import { DateTime } from "luxon";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { IEntry } from "../../types/types";
import { ItemTypes, TColumns } from "./EntriesTable";
import { TableCell } from "./TableCell";

interface ITableRowProps {
  columns: TColumns[];
  entry: IEntry;
  index: number;
  groupIndex: string;
  onEdit: (entry: IEntry) => void;
  openDeleteDialog: (entry: IEntry) => void;
  moveEntry: (
    item: { index: number; entry: IEntry },
    hoverIndex: number,
    groupIndex: string
  ) => void;
  enableDrag: boolean;
  refetch:
    | ((
        variables?:
          | Partial<{
              periodStart: DateTime;
              periodEnd: DateTime;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | (() => Promise<[ApolloQueryResult<any>, ApolloQueryResult<any>]>);
}

export const EntriesTableRow = ({
  columns,
  groupIndex,
  entry,
  index,
  onEdit,
  openDeleteDialog,
  moveEntry,
  enableDrag,
  refetch,
}: ITableRowProps) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current || !item) {
        return;
      }
      //@ts-ignore
      const dragIndex = item.index;
      //@ts-ignore
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      // @ts-ignore
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // @ts-ignore
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveEntry(item as { index: number; entry: IEntry }, hoverIndex, groupIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // @ts-ignore
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: () => {
      return { entry, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => enableDrag,
  });

  drag(drop(ref));

  return (
    <tr
      key={entry.entryId}
      ref={ref}
      style={{
        opacity: isDragging ? 0.3 : 1,
      }}
      data-handler-id={handlerId}
      className={`budget-table__row--${entry.type}`}
    >
      {_.map(columns, (column) => {
        return (
          <td
            key={column}
            className={`budget-table-${column} border border-white dark:border-gray-700 p-2`}
          >
            <TableCell
              entry={entry}
              column={column}
              openDeleteDialog={openDeleteDialog}
              onEdit={onEdit}
              refetch={refetch}
            />
          </td>
        );
      })}
    </tr>
  );
};
