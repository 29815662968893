import { IAccount } from "../../types/types";
import FormBuilder from "./FormBuilder";
import GoalSchema from "./schemas/goal";
import React from "react";

const GoalForm = ({
  account,
  onApply,
  onCancel,
  onChange,
}: {
  account: IAccount;
  onApply: (account: IAccount) => Promise<any>;
  onCancel: () => void;
  onChange: (key: string, value: any) => void;
}) => {
  return (
    <div className="m-4">
      <FormBuilder
        formSchema={GoalSchema}
        entity={account}
        onChange={onChange}
        onApply={onApply}
        onCancel={onCancel}
        additionalOptions={{}}
      />
    </div>
  );
};
export default GoalForm;
