import { Button, Menu, MenuItem, Popover, Switch } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { DateTime } from "luxon";
import { FC, default as React, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../utils/utils";
import ExportDialog, { ExportOptions } from "../common/ExportDialog";
import SelectedMonthChanger from "../common/SelectedMonthChanger";
import TableHeader from "../common/TableHeader";

const SWITCHERS = ["includeIncomes", "includeExpenses", "includeValues"];

interface IBudgetHeaderProps {
  setCurrentMonthAsSelectedDate: () => void;
  setPrevMonthAsSelectedDate: () => void;
  setNextMonthAsSelectedDate: () => void;
  selectedDate: DateTime;
  onExport: (options: ExportOptions) => void;
}

const BudgetHeader: FC<IBudgetHeaderProps> = ({
  setCurrentMonthAsSelectedDate,
  setPrevMonthAsSelectedDate,
  setNextMonthAsSelectedDate,
  onExport,
  selectedDate,
}) => {
  const { t } = useTranslation();

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const openExportDialog = useCallback(() => {
    setIsExportDialogOpen(true);
  }, []);

  const closeExportDialog = useCallback(() => {
    setIsExportDialogOpen(false);
  }, []);

  // This one cannot be with useCallback because it would call onExport with data from the first render
  // so data objects will be empty

  const exportDashboard = () => {
    onExport(exportOptions);
    closeExportDialog();
  };

  const [exportOptions, setExportOptions] = React.useState<ExportOptions>({
    includeIncomes: true,
    includeExpenses: true,
    includeValues: true,
  });

  return (
    <React.Fragment>
      <TableHeader
        leftElement={
          <SelectedMonthChanger
            setCurrentMonthAsSelectedDate={setCurrentMonthAsSelectedDate}
            setPrevMonthAsSelectedDate={setPrevMonthAsSelectedDate}
            setNextMonthAsSelectedDate={setNextMonthAsSelectedDate}
            selectedDate={selectedDate}
            vertical={false}
          />
        }
        rightElement={
          !isMobile() ? (
            <div>
              <Popover
                content={
                  <div className="p-2">
                    <Menu>
                      <MenuItem
                        onClick={() => openExportDialog()}
                        icon={IconNames.EXPORT}
                        text={t("actions.export")}
                      />
                    </Menu>
                  </div>
                }
              >
                <Button icon={IconNames.COG} className="bp3-button bp3-minimal bp3-icon-more">
                  {!isMobile() && t("actions.settings")}
                </Button>
              </Popover>
            </div>
          ) : null
        }
      />
      <ExportDialog
        isOpen={isExportDialogOpen}
        onClose={closeExportDialog}
        onExport={exportDashboard}
      >
        {SWITCHERS.map((switcherKey) => (
          <Switch
            key={switcherKey}
            checked={exportOptions[switcherKey as keyof ExportOptions]}
            label={t(`export_options.${_.snakeCase(switcherKey)}`)}
            onChange={() =>
              setExportOptions({
                ...exportOptions,
                [switcherKey]: !exportOptions[switcherKey as keyof ExportOptions],
              })
            }
          />
        ))}
      </ExportDialog>
    </React.Fragment>
  );
};

export default BudgetHeader;
