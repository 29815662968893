import { ApolloQueryResult } from "@apollo/client";
import _ from "lodash";
import { DateTime } from "luxon";
import { IEntry } from "../../types/types";
import React from "react";
import { parseDate } from "../../utils/utils";
import { TColumns } from "./EntriesTable";
import { EntriesTableRow } from "./EntriesTableRow";

interface IEntriesTBodyProps {
  entries: IEntry[];
  columns: TColumns[];
  date: string;
  openDeleteDialog: (entry: IEntry) => void;
  onEdit: (entry: IEntry) => void;
  updateInBackground: (entry: IEntry) => void;
  enableDrag: boolean;
  refetch:
    | ((
        variables?:
          | Partial<{
              periodStart: DateTime;
              periodEnd: DateTime;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<any>>)
    | (() => Promise<[ApolloQueryResult<any>, ApolloQueryResult<any>]>);
  moveEntry: (
    item: { index: number; entry: IEntry },
    hoverIndex: number,
    groupIndex: string
  ) => void;
}

export const EntriesTBody = ({
  columns,
  date,
  enableDrag,
  entries,
  moveEntry,
  refetch,
  onEdit,
  openDeleteDialog,
}: IEntriesTBodyProps) => {
  return (
    <tbody>
      <tr>
        <td colSpan={columns.length} className="">
          <div className="pt-8 pb-2 font-semibold">{parseDate(date).toFormat("dd MMMM yyyy")}</div>
        </td>
      </tr>
      {_.map(entries, (entry, index) => {
        return entry ? (
          <EntriesTableRow
            columns={columns}
            enableDrag={enableDrag}
            entry={entry}
            groupIndex={date}
            index={index}
            key={entry.id}
            moveEntry={moveEntry}
            onEdit={onEdit}
            openDeleteDialog={openDeleteDialog}
            refetch={refetch}
          />
        ) : null;
      })}
    </tbody>
  );
};
