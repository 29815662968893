import { Colors } from "@blueprintjs/core";
import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { useContext, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { IBudgetEntry, IBudgetSection, ICurrency } from "../../types/types";
import { isMobile } from "../../utils/utils";
import { UserContext } from "../WithUserContext";
import { filterBudgetEntriesForCurrentMonth } from "../../utils/budgetUtils";

type Props = {
  selectedDate: DateTime;
  budgetSections: IBudgetSection[];
};

// const RADIAN = Math.PI / 180;
// //@ts-ignore
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

const renderLabel = (currency: string) => (entry: { name: string; value: number }) => {
  return `${entry.name} - ${entry.value.toFixed(2)}${currency || ""}`;
};

const COLORS = _.values(_.omit(Colors, "BLACK"));

const BudgetCharts = ({ selectedDate, budgetSections }: Props) => {
  const [currentBudgetSection, setCurrentBudgetSection] = useState<number | null>(null);
  const userData = useContext(UserContext);
  const darkModeOn = userData?.settings["darkMode"];
  const data = currentBudgetSection
    ? _.map(
        filterBudgetEntriesForCurrentMonth(
          _.find(budgetSections, (budgetSection) => budgetSection.id === currentBudgetSection)
            ?.budget_entries as IBudgetEntry[],
          selectedDate
        ),
        (budget_entry) => {
          return {
            id: budget_entry.id,
            name: budget_entry.category.name,
            value: budget_entry.value,
          };
        }
      )
    : _.map(budgetSections, (budgetSection) => {
        return {
          id: budgetSection.id,
          name: budgetSection.section_name,
          value: _.reduce(
            filterBudgetEntriesForCurrentMonth(budgetSection.budget_entries, selectedDate),
            (acc, entry) => {
              return (acc += entry.value);
            },
            0
          ),
        };
      });

  return (
    <div className="text-center">
      <PieChart width={isMobile() ? 480 : 1000} height={isMobile() ? 480 : 600} className="mx-auto">
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          label={renderLabel((userData?.settings?.currency as ICurrency)?.symbol || "")}
          outerRadius={250}
          dataKey="value"
          nameKey={"name"}
        >
          {data.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[(index + 1) * (darkModeOn ? 5 : 1)]}
                onClick={() => setCurrentBudgetSection((id) => (id ? null : (entry.id as number)))}
              />
            );
          })}
        </Pie>
      </PieChart>
    </div>
  );
};

export default BudgetCharts;
